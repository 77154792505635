import rest from "rest";
import defaultRequest from "rest/interceptor/defaultRequest";
import mime from "rest/interceptor/mime";
import pathPrefix from "rest/interceptor/pathPrefix";
import errorCode from "rest/interceptor/errorCode";
import csrf from "rest/interceptor/csrf";

import extractData from "./interceptor/extract_data";
import { camelize, decamelize } from "./interceptor/camelize_data";

const client = rest
  .wrap(mime)
  .wrap(pathPrefix, { prefix: "/v1" })
  .wrap(errorCode)
  .wrap(camelize)
  .wrap(extractData);

export default {
  get: (path) => client(path),
  post: (path, entity, headers) =>
    client.withCSRF({ path, headers, entity, method: "POST" }),
  put: (path, entity) => client.withCSRF({ path, entity, method: "PUT" }),
  delete: (path, headers) =>
    client.withCSRF({ path, headers, method: "DELETE" }),

  // This is called in data/settings.js once the csrf token is received from the server.
  setCSRFToken: (token) => {
    client.withCSRF = client
      .wrap(defaultRequest, { headers: { "Content-Type": "application/json" } })
      .wrap(csrf, { token })
      .wrap(decamelize);
  },
};
