import interceptor from "rest/interceptor";

/**
 * Authenticates the request using JWT
 *
 * @param {Client} [client] client to wrap
 * @param {string} config.jwt JWT token
 *
 * @returns {Client}
 */
export default interceptor({
  request: function handleRequest(request, config) {
    const headers = request.headers || (request.headers = {});
    const token = request.token || config.token;
    if (token) {
      headers.Authorization = `Bearer ${token}`;
      headers["Content-Type"] = "application/json";
    }

    return request;
  },
});
