import { action, runInAction , makeObservable } from "mobx";

import objectPath from "object-path";

export class BaseModel {
  constructor(data) {
    makeObservable(this, {
      update: action,
      get: action,
      set: action
    });

    runInAction(() => {
      this.update(data);
    });
  }

  update = (data) => {
    // this is replacing the old extendObservable method, which is now depricated
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        // to dynamically update properties that contain models, we must identiy them and handle them differently
        // otherwise the model will be donverted to a plain object
        if (this[key] instanceof Array && data[key] != undefined) {
          if (this[key][0] instanceof BaseModel && typeof data[key][0] === 'object') {
            for (let i=0; i< data[key].length; i++) {
              if (this[key][i] == undefined) {
                this[key][i] = data[key][i]
              } else {
                this[key][i].update(data[key][i]);
              }
            }
          }
        }
         else {
          objectPath.set(this, key, data[key]);
        }
      }
    }
    return this;
  }

  get = (key) => {
    return objectPath.get(this, key);
  }

  set = (key, value) => {
    objectPath.set(this, key, value);
  }
}
