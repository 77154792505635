import {CustomerStore} from "./CustomerStore";
import {ProjectStore} from "./ProjectStore";
import {InspectionStore} from "./InspectionStore";
import { AssetStore } from "./AssetStore";
import { DashboardStore } from "./DashboardStore";

// This is the root store that will be provided to the app
class AppStore{
  constructor(){
    this.customerStore = new CustomerStore(this);
    this.projectStore = new ProjectStore(this);
    this.inspectionStore = new InspectionStore(this);
    this.assetStore = new AssetStore(this);
    this.dashboardStore = new DashboardStore(this);
  }
}

export default AppStore;