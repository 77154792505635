/* eslint-disable max-classes-per-file */

class CookieStorage {
  static setItem(key, value) {
    document.cookie = `${key}=${value}`;
  }

  static getItem(key) {
    const match = document.cookie.match(RegExp(`(?:^|;\\s*)${key}=([^;]*)`));
    return match ? match[1] : null;
  }

  static removeItem(key) {
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  }
}

class LocalStorageWrapper {
  constructor() {
    this.storageBackend = window.localStorage;
    try {
      this.storageBackend.setItem("a", 1);
    } catch (e) {
      this.storageBackend = CookieStorage;
    }
  }

  setItem(key, value) {
    if (this.storageBackend) {
      this.storageBackend.removeItem(key);
      this.storageBackend.setItem(key, JSON.stringify(value));
    }
  }

  getItem(key) {
    if (this.storageBackend) {
      try {
        return JSON.parse(this.storageBackend.getItem(key));
      } catch (e) {
        console.log(e);
      }
    }
    return null;
  }

  removeItem(key) {
    if (this.storageBackend) {
      this.storageBackend.removeItem(key);
    }
  }

  onSetSession() {
    this.removeItem("mapping-filters");
  }
}

const localStorageWrapper = new LocalStorageWrapper();
export default localStorageWrapper;
