import { observable, action, makeObservable, toJS } from "mobx";
import JwtClient from "../../../data/api/jwt_client"
import { makePersistable } from 'mobx-persist-store';
import EncryptedLocalStorageAdapter from "../../../util/encryptedLocalStorageAdapter";

export class CustomerStore{

  customers = [];
  currentCustomer;

  constructor(appStore){
    
    makeObservable(this, {
      customers: observable,
      currentCustomer: observable,
      setCustomers: action,
      setCurrentCustomer: action,
    })

    makePersistable(this, {
      name: 'CustomerStore',
      properties: ['currentCustomer'],
      storage: new EncryptedLocalStorageAdapter(),
      expireIn: 86400000
    })

    this.appStore = appStore;
  }

  setCurrentCustomer = (currentCustomer) => {
    this.currentCustomer = currentCustomer;
    this.appStore.projectStore.setProjects([]);
    this.appStore.projectStore.setCurrentProject(null);
    this.appStore.assetStore.clearAssets();
  }

  setCustomers = (customers) => {
    this.customers = customers;
  }

  addUtilityCustomer = (name) => {
    const params = {
      name: name,
      app: "utilities"
    }
    return JwtClient.post("customers", params).then((res) => {
      return "done";
    });

  }

  fetchCustomers = () => {
    return JwtClient.get("customers/dropdown_customers").then((res) => {
      if (res.length) {
        this.setCustomers(res);
        return this.customers;
      } else {
        this.setCustomers([res]);
        return this.customers;
      }
    });
  }

  zipAndDownloadFiles = (category) => {
    // TODO: write this, probably needs a queue service
  }
}