import { action, observable, makeObservable } from "mobx";
import { BaseModel } from "kilowatt-front/state/BaseModel";

export class BaseStore {
  model = BaseModel;

  _all = null;

  _cacheById = observable.map({});

  constructor() {
    makeObservable(this, {
      _all: observable,
      _cacheById: observable,
      addOrUpdate: action,
      addOrUpdateMulti: action,
      remove: action
    });
  }

  addOrUpdate = (data) => {
    const { id } = data;
    let model = this._cacheById.get(id);
    if (model) {
      // force the data through the model constructor so that we transform
      // necessary child objects into models
      let new_model = new this.model(data);
      model.update(new_model);
    } else {
      model = new this.model(data);
      this._cacheById.set(id, model);
      this._all = this._all ? [...this._all, model] : [model];
    }
    return this._cacheById.get(id);
  }

  addOrUpdateMulti = (records) => {
    return records.map(this.addOrUpdate, this);
  }

  remove = (model) => {
    const { id } = model;
    this._cacheById.delete(id);
    this._all.remove(model);
  }
}
