import { observable, action, makeObservable, toJS } from "mobx";
import JwtClient from "../../../data/api/jwt_client"
import { makePersistable } from 'mobx-persist-store';
import EncryptedLocalStorageAdapter from "../../../util/encryptedLocalStorageAdapter";

/**
 * Represents a ProjectStore that manages projects and current project state.
 * @class
 */
export class ProjectStore {
  /**
   * An array of projects.
   * @type {Array}
   */
  projects = [];

  /**
   * The current project.
   * @type {Object}
   */
  currentProject;

  /**
   * Creates an instance of ProjectStore.
   * @param {AppStore} appStore - The parent app store.
   */
  constructor(appStore) {
    /**
     * Observable properties and actions for MobX.
     */
    makeObservable(this, {
      projects: observable,
      currentProject: observable,
      setProjects: action,
      setCurrentProject: action,
    });

    /**
     * Makes the currentProject property persistable.
     */
    makePersistable(this, {
      name: 'ProjectStore',
      properties: ['currentProject'],
      storage: new EncryptedLocalStorageAdapter(),
      expireIn: 86400000,
    });

    this.appStore = appStore;
  }

  /**
   * Sets the projects array.
   * @param {Array} projects - The projects to set.
   */
  setProjects = (projects) => {
    this.projects = projects;
  }

  /**
   * Sets the current project.
   * @param {Object} currentProject - The current project to set.
   */
  setCurrentProject = (currentProject) => {
    this.currentProject = currentProject;
    this.appStore.inspectionStore.setInspections([]);
    this.appStore.inspectionStore.setCurrentInspection(null);
    this.appStore.assetStore.clearAssets();
  }

  /**
   * Adds a utility project.
   * @param {string} name - The name of the utility project.
   * @returns {Promise<string>} A promise that resolves to "done" when the project is added.
   */
  addUtilityProject = (name) => {
    const params = {
      name: name,
      customer_id: this.appStore.customerStore.currentCustomer.id,
    }
    return JwtClient.post("projects", params).then((res) => {
      return "done";
    });
  }

  /**
   * Fetches the projects for the current customer.
   * @returns {Promise<Array|null>} A promise that resolves to the fetched projects array or null if no projects are found.
   */
  fetchProjects = () => {
    return JwtClient.get(`customers/${this.appStore.customerStore.currentCustomer.id}/projects`).then((res) => {
      if (res.length > 0) {
        this.setProjects(res);
        return this.projects;
      } else {
        this.setProjects(null);
        return this.projects;
      }
    });
  }
}