import React from "react";
import { Routes, Route } from "react-router-dom";

// components
import Login from "kilowatt-front/components/Auth/Login";
import Callback from "kilowatt-front/components/Auth/Callback";
import VerifyEmail from "kilowatt-front/components/Auth/VerifyEmail";
import NotFound from "kilowatt-front/shared_components/Error/NotFound.jsx";

// ----END OF IMPORTS-------------------------------------------------------

const LoginRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login/>} />
      <Route path="callback" element={<Callback />} />
      <Route path="email_verification" element={<VerifyEmail />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
};

export default LoginRoutes;