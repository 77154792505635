import React from "react";
import classNames from "classnames";

import "./Button.scss";

const Button = ({
  buttonType,
  children,
  circle,
  className,
  disabled,
  download,
  flat,
  icon,
  iconName,
  name,
  onClick,
  onMouseDown,
  onTouchStart,
  raised,
  selected,
  tooltip,
  type = "submit"
}) => {
  let classes = classNames({
    button: true,
    "raised-button": raised,
    "circle-button": circle,
    "flat-button": flat,
    "has-icon": iconName,
    selected,
    disabled,
  });

  classes += ` ${buttonType || "default"}-button ${className}`;
  let iconComponent = null;
  if (icon) {
    iconComponent = icon;
  } else if (iconName) {
    iconComponent = <i className="material-icons">{iconName}</i>;
  }

  return (
    <button
      className={classes}
      disabled={disabled}
      download={download}
      name={name}
      title={tooltip}
      type={type}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
    >
      {iconComponent}
      {children}
    </button>
  );
};

export default Button;