import React from "react";

// componenets
import { Outlet } from "react-router-dom";

// styling
import "./App.scss";
import { ThemeProvider, createTheme } from '@mui/material/styles';

// ----END OF IMPORTS-------------------------------------------------------

const kilowatt_mui_theme = createTheme({
  palette: {
    primary: {
      main: "#fd6d21", //kpl-brand-orange
    },
    kpl: {
      "brand-orange": "#fd6d21",
      "dark-gray-1": "rgb(38, 39, 42)",
      "dark-gray-4": "rgb(68, 69, 72)"
    }
  },
});

const App = () => (
  <ThemeProvider theme={kilowatt_mui_theme}>
    <div className="app full-height" data-testid="app-div">
      <Outlet />
    </div>
  </ThemeProvider>
);

export default App;

