import { BaseStore } from "../../BaseStore";
import API from "kilowatt-front/data/api";
import { LOADING, SUCCESS } from "kilowatt-front/constants/loading_status";
import { CustomerModel } from "kilowatt-front/state/access/models/CustomerModel";
import { makeObservable, observable, action, runInAction } from "mobx";

export class CustomerStore extends BaseStore {
  selectedCustomer = null;
  
  constructor() {
    super();
    makeObservable(this, {
      selectedCustomer: observable,
      getAdminedCustomer: action
    });
  }
  model = CustomerModel;

  _status = {};

  get adminedCustomers() {
    this._maybeFetchAdminedCustomers();
    return this._all;
  }

  // made this into a promise chain because I want to wait until it completes before assigning the result to a variable

  getAdminedCustomer = async (id) => {
    const data = await API.fetchAdminedCustomer(id);
    runInAction(() => {
      this.selectedCustomer = new CustomerModel(data);
    });
  }

  _maybeFetchAdminedCustomers = () => {
    if (
      !this._status.all &&
      this._status.all !== LOADING &&
      this._status.all !== SUCCESS
    ) {
      this._status.all = LOADING;
      API.fetchAdminedCustomers().then((customers) => {
        this._status.all = SUCCESS;
        this.addOrUpdateMulti(customers);
      });
    }
  }

  _maybeFetchAdminedCustomer = (id) => {
    this._status[id] = LOADING;
    return API.fetchAdminedCustomer(id).then((customer) => {
      this._status[id] = SUCCESS;
      return this.addOrUpdate(customer);
    });
  }
}

const StoreInstance = new CustomerStore();
export default StoreInstance;