/* eslint-disable no-underscore-dangle */

// _elev is provided by the library so no choice here

// This detects if the snippet is present, it still may not be ready yet.
export function haveElevio() {
  return typeof window !== "undefined" && typeof window._elev !== "undefined";
}

let userData;

export function changeUser(userInfo) {
  userData = userInfo;
}

function checkElevioReady() {
  if (haveElevio()) {
    if (userData && typeof window._elev.setUser === "function") {
      window._elev.setUser(userData);
      clearInterval(checkElevioInterval);
    }
  }
}

const checkElevioInterval = setInterval(checkElevioReady, 1000); // Check every 1 second

export function logoutUser() {
  userData = null;
  if (haveElevio()) {
    if (window._elev.logoutUser) {
      window._elev.logoutUser();
    } else {
      window._elev.user = null;
      window._elev.events = {
        afterLoad() {
          window._elev.logoutUser();
        },
      };
    }
  }
}
