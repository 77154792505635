import rest from "rest";
import defaultRequest from "rest/interceptor/defaultRequest";
import mime from "rest/interceptor/mime";
import pathPrefix from "rest/interceptor/pathPrefix";
import errorCode from "rest/interceptor/errorCode";
import jwt from "./interceptor/jwt";

import extractData from "./interceptor/extract_data";
import { camelize, decamelize } from "./interceptor/camelize_data";

const calls = {
  client: rest
    .wrap(mime)
    .wrap(errorCode)
    .wrap(camelize)
    .wrap(extractData)
    .wrap(decamelize)
    .wrap(defaultRequest, { headers: { "Content-Type": "application/json" } })
    .wrap(pathPrefix, { prefix: "/v1" }),
  formClient: rest
    .wrap(mime)
    .wrap(errorCode)
    .wrap(camelize)
    .wrap(extractData)
    .wrap(decamelize)
    .wrap(defaultRequest, {
      headers: { "Content-Type": "multipart/form-data" },
      method: "POST",
    })
    .wrap(pathPrefix, { prefix: "/v1" }),
  get: (path) => calls.clientWithJwt(path),
  post: (path, entity) => calls.clientWithJwt({ path, entity, method: "POST" }),
  postForm: (path, data, authService) => {
    // unable to get "multipart/form-data" request to work in the 'rest' library
    // so using 'fetch' // TODO: investigate further and resolve
    const token = authService.getFromLocalStorage("id_token");
    return fetch(`/v1/${path}`, {
      headers: { Authorization: `Bearer ${token}` },
      method: "POST",
      body: data,
    }).then((response) => {
      if (response.ok) return response;
      throw new Error(`${response.statusText}`);
    });
  },
  postFormRest: (path, data, authService) => {
    const token = authService.getFromLocalStorage("id_token");
    return calls.formClient({
      path,
      entity: data,
      headers: { Authorization: `Bearer ${token}` },
      method: "POST",
    });
  },
  put: (path, entity) => calls.clientWithJwt({ path, entity, method: "PUT" }),
  delete: (path) =>
    calls.clientWithJwt({ path, method: "DELETE", entity: null }),
  request: (data) => calls.client.withJWT(data),

  setJWTToken(authService) {
    this.authService = authService;
    this.wrapJWTToken();
  },

  wrapJWTToken() {
    const token = this.authService.getFromLocalStorage("id_token");
    calls.client.withJWT = calls.client.wrap(jwt, { token });
  },

  copyObjectData(data) {
    if (typeof data === "string") {
      return data;
    }
    return Object.assign({}, data);
  },

  clientWithJwt(data) {
    return calls.client.withJWT(this.copyObjectData(data)).catch((e) => {
      if (e.status && e.status.code === 401) {
        this.authService
          .refreshToken()
          .catch(() => {
            this.authService.goBackToLoginManually();
          })
          .then(() => {
            this.wrapJWTToken();
            return calls.client.withJWT(this.copyObjectData(data));
          });
      } else if (e.status && e.status.code == 403) {
        return e;
      } else if (e.status) {
        throw e;
      }
    });
  },
};

export default calls;
