import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";

// components
import Button from "kilowatt-front/shared_components/Button/Button";

// stores and services
import AuthService from "kilowatt-front/services/AuthService";
import JwtClient from "kilowatt-front/data/api/jwt_client";
import { CurrentUserStore } from "kilowatt-front/state/domain/stores/CurrentUserStore";

// styling
import "./VerifyEmail.scss";

// ----END OF IMPORTS-------------------------------------------------------
const VerifyEmail = ({auth, currentUserStore}) => {
  const navigate = useNavigate();
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    const idToken = auth.getTokenProfile();

    // TODO: not really related but I was working on this when I noticed it so Im putting it here:
    // Logging out doesnt reset the cookie, so if you login to a user with unverifed email, then
    // logout, and log into a verifid user, it kicks back to the verify email route.

    // TODO: fix this
    // if (!emailSent) {
    //   JwtClient.post("/session/send_verification", {
    //     email: idToken.email,
    //     user_id: idToken.user_id,
    //   });
    // }
  }, [])

  const showLogin = () => {
    navigate("/app/auth");
  }

  const resendEmail = () => {
    currentUserStore
      .resendVerificationEmail()
      .then(() => setEmailSent(true));
  }

  let content =
    "To log in to Kespry Cloud, please verify your email address by clicking on the link sent to your email.";

  if (currentUserStore.userProfile && currentUserStore.userProfile.email) {
    content = (
      <div>
        To log in to Kespry Cloud, please verify your email address by
        clicking on the link sent to{" "}
        <strong>{currentUserStore.userProfile.email}</strong>.
      </div>
    );
  }

  const emailButtonText = emailSent
    ? "Verification Email Sent"
    : "Resend Verification Email";

  return (
    <div className="modal show verify-email-modal">
      <div className="modal-content-wrapper">
        <div className="modal-content">
          <div className="modal-description">{content}</div>
          <div className="modal-actions">
            <Button onClick={showLogin}>Return to Login</Button>
            <Button onClick={resendEmail}>{emailButtonText}</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

VerifyEmail.propTypes = {
  currentUserStore: PropTypes.instanceOf(CurrentUserStore),
  auth: PropTypes.instanceOf(AuthService),
};

export default inject("currentUserStore", "auth")(observer(VerifyEmail));
