import Auth0Lock from "auth0-lock";
import {jwtDecode} from "jwt-decode";
import moment from "moment";
import _ from "lodash";

import JwtClient from "kilowatt-front/data/api/jwt_client"

import localStorageWrapper from "kilowatt-front/util/localStorageWrapper";

// ----END OF IMPORTS-------------------------------------------------------

// TODO: convert to functional class in acordance with React 18
export default class AuthService {
  constructor(
    clientID,
    domain,
    redirectUrl,
    indexUrl,
    showSignup,
    emailVerified,
    currentUserStore,
  ) {
    this.indexUrl = indexUrl;
    this.clientID = clientID;
    this.showSignup = showSignup;
    this.emailVerified = emailVerified;
    this.currentUserStore = currentUserStore;

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.setSession = this.setSession.bind(this);
    this.setTokenAndFinish = this.setTokenAndFinish.bind(this);
    this.isVerified = this.isVerified.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.displayError = this.displayError.bind(this);

    this.createAuth0Dependencies(clientID, domain, redirectUrl);
    this.handleAuthentication();
  }

  createAuth0Dependencies(clientID, domain, redirectUrl) {
    this.lock = new Auth0Lock(clientID, domain, {
      auth: {
        redirectUrl,
        responseType: "token id_token",
        // including audience param triggers OIDC-conformant flow, which signs JWTs with RSA256,
        // (and would break things if triggered at this stage)
        // audience: "https://corona.kespry.com",
        // sso: true,
        params: {
          // nonce: this.getFromLocalStorage("nonce"),
          // prompt: none,
          // Scopes are configured in the Auth0 rules on the Auth0 dashboard
          scope:
            "openid email name user_id customer elevio_email_key offline_access app_metadata customers is_kespry trusted_domain",
          // This state is handled in the Auth0 rules.
          // TODO: create a new rule for kilowatt in the Auth0 Dashboard
          // right now, it will default to using the users/authorize in corona due to state mismatch in the rule
          state: `${window.location.origin}/backend_api/v1/users/authorize`,
        },
      },
      theme: {
        logo: window.imageAssets["kespry-black-logo.svg"],
        primaryColor: "#FF6D00",
      },
      languageDictionary: {
        title: "",
        signupTitle: "",
        forgotPasswordTitle: "",
      },
      configurationBaseUrl: "https://cdn.auth0.com",
      closable: false,
      initialScreen: this.showSignup ? "signUp" : "login",
      rememberLastLogin: false,
      _enableIdPInitiatedLogin: true
    });
  }

  login() {
    this.lock.show();
  }

  logout() {
    this.removeFromLocalStorage(
      "access_token",
      "id_token",
      "expires_at",
      "nonce",
      "CustomerStore",
      "ProjectStore",
      "InspectionStore",
      "AssetStore"
    );
    this.currentUserStore.setProfile(null);
    // logs out of Auth0 SSO session
    this.lock.logout({
      clientID: this.clientID,
      returnTo: `${window.location.origin}/app/auth`,
    });
    // logs out of application session
    JwtClient.delete("/session");
  }

  handleAuthentication() {
    this.lock.on("authenticated", (authResult) => {
        this.setSession(authResult);
    });

    this.lock.on("authorization_error", (error) => {
        if (error.errorDescription) {
            this.setInLocalStorage("login_error", error.errorDescription);
            window.location = this.getLoginRoute();
        }
    });
  }

  displayError(error) {
    return this.lock.show({
      flashMessage: {
        type: "error",
        text: error,
      },
    });
  }

  setSession(authResult, redirect = true, expires = null) {
    const { idToken, accessToken } = authResult;
    const expiresAt = expires || jwtDecode(idToken).exp;
    this.setInLocalStorage("access_token", accessToken);
    this.setInLocalStorage("id_token", idToken);
    this.setInLocalStorage("expires_at", expiresAt);
    localStorageWrapper.onSetSession();

    if (redirect) {
      this.loadOriginalLocation();
    }
  }

  loadOriginalLocation() {
    let nextLocation = localStorageWrapper.getItem("original_location");
    if (nextLocation) {
      this.removeFromLocalStorage("original_location");
      window.location.replace(nextLocation);
    } else {
      window.location.replace(this.indexUrl);
    }
  }

  // called every time user navigates to a Protected route
  requireAuth = (nextState, replace, callback) => {
    this.checkForTokenInUrl(nextState);
    if (this.isAuthenticated()) {
      return this.setTokenAndFinish(replace, callback);
    }
    return this.goBackToLogin(nextState, replace, callback);
  };

  // Needed for pdf-renderer to authenticate
  checkForTokenInUrl(nextState) {
    if (
      nextState &&
      nextState.location &&
      nextState.location.query &&
      nextState.location.query.idToken
    ) {
      const expires = moment().add(1, "hour").unix();
      const authResult = {
        idToken: nextState.location.query.idToken,
        accessToken: "unimportantToken",
      };
      this.setSession(authResult, false, expires);
    }
  }

  isAuthenticated() {
    const expiresAt = JSON.parse(localStorageWrapper.getItem("expires_at"));
    return moment().unix() < expiresAt;
  }

  isVerified() {
    // TODO: uncomment this after creating auth0 rule that adds metadata
    const kilowattEmailVerified =
      this.currentUserStore.userProfile.kilowatt_email_verified === "complete";
    const auth0EmailVerified = this.currentUserStore.userProfile.email_verified === "complete";
    return kilowattEmailVerified || auth0EmailVerified;
  }

  setTokenAndFinish(navigate, callback) {
    const idToken = this.getTokenProfile();
    JwtClient.setJWTToken(this);
    return this.reloadSessionCookie()
      .catch(() => {
        console.warn("unable to reload session cookie");
      })
      .then(() => {
        this.currentUserStore.setProfile(idToken);
        this.currentUserStore.updateProfile().then(() => {
          if (!this.isVerified()) {
            navigate("/app/auth/email_verification");
          } else if (
            this.currentUserStore.app &&
            window.location.pathname.match("redirect")
          ) {
            navigate(`/app/${this.currentUserStore.app}`);
          }
        });
      });
  }

  goBackToLogin(nextState, replace, callback) {
    this.setInLocalStorage("original_location", nextState.location.pathname + nextState.location.search);
    if (this.currentUserStore) {
      this.currentUserStore.setProfile(null);
    }
    replace({ pathname: this.getLoginRoute() });
    callback();
    return null;
  }

  goBackToLoginManually() {
    this.setInLocalStorage("original_location", window.location);
    this.currentUserStore.setProfile(null);
    window.location = this.getLoginRoute();
  }

  refreshToken() {
    return new Promise((resolve, reject) => {
      this.lock.checkSession({}, (err, authResult) => {
        if (err && err.error) {
          reject(err);
        } else {
          this.setToken(authResult.id_token);
          resolve(authResult.id_token);
        }
      });
    });
  }

  getLoginRoute() {
    return `${this.indexUrl}/auth`;
  }

  getTokenProfile() {
    const token = this.getFromLocalStorage("id_token");
    if (token) {
      return jwtDecode(token);
    }
    return null;
  }

  reloadSessionCookie() {
    return JwtClient.get("/session/refresh_cookie");
  }

  getFromLocalStorage(field) {
    let item = localStorageWrapper.getItem(field);

    if (field === "id_token") {
      item = item;
    }
    if (!item) {
      item = field
        .split("_")
        .map((word) => _.capitalize(word))
        .join(" ");
      throw new Error(`No ${item} found`);
    }
    return item;
  }

  getError() {
    const error = localStorageWrapper.getItem("login_error");
    if (error) {
      this.removeFromLocalStorage("login_error");
    }
    return error;
  }

  setInLocalStorage(field, message) {
    localStorageWrapper.setItem(field, message);
  }

  removeFromLocalStorage(...fields) {
    fields.map((field) => localStorageWrapper.removeItem(field));
  }
}
