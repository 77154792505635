import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";

// services
import AuthService from "kilowatt-front/services/AuthService";

// styling
import "./Login.scss";

// ----END OF IMPORTS-------------------------------------------------------

const Login = ( {auth} ) => {
  useEffect(() => {
    auth.login();
  }, [auth]);

  const loginError = auth.getError();
  if (loginError) {
    auth.displayError(loginError);
  }

  return <div className="login full-height" />;
}

Login.propTypes = {
  auth: PropTypes.instanceOf(AuthService),
};

export default inject("auth")(observer(Login));