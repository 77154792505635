import client from "./client";

export default {
  // Access Control Management

  fetchAdminedCustomers: () =>
    client.get("auth/customers").then(({ customers }) => customers),

  fetchAdminedCustomer: (customerId) =>
    client.get(`auth/customers/${customerId}`).then(({ customer }) => customer),

  addDomainToCustomer: (customerId, domain) =>
    client.post(`auth/customers/${customerId}/domains`, { domain }),

  removeDomainFromCustomer: (customerId, domainId) =>
    client.delete(`auth/customers/${customerId}/domains/${domainId}/`),

  createGroup: (customerId, group) =>
    client
      .post(`auth/customers/${customerId}/groups`, { group }),

  updateGroup: (group) =>
    client.put(`auth/groups/${group.id}`, { group }).then(({ group }) => group),

  removeGroup: (group) => client.delete(`auth/groups/${group.id}`),

  addProjectToGroup: (groupId, projectId) =>
    client.post(`auth/groups/${groupId}/projects`, { projectId }),

  removeProjectFromGroup: (groupId, projectId) =>
    client.delete(`auth/groups/${groupId}/projects/${projectId}`),

  addUserToGroup: (groupId, email, permission) =>
    client
      .post(`auth/groups/${groupId}/users`, { email, permission })
      .then(({ user }) => user),

  changeUserPermission: (groupId, userId, permission) =>
    client.put(`auth/groups/${groupId}/users/${userId}`, { permission }),

  removeUserFromGroup: (groupId, userId) =>
    client.delete(`auth/groups/${groupId}/users/${userId}`),

  updateCustomer: (customerId, customer) =>
    client
      .put(`auth/customers/${customerId}`, { customer })
      .then(({ customer }) => customer)
};
