import { observable, action, makeObservable, toJS } from "mobx";
import JwtClient from "../../../data/api/jwt_client"
import { makePersistable } from 'mobx-persist-store';
import EncryptedLocalStorageAdapter from "../../../util/encryptedLocalStorageAdapter";


export class AssetStore{

  assets;
  pointsBaseLayer;
  hexGrids;
  linesBaseLayer;
  pinkAssets;
  yellowAssets;
  orangeAssets;
  redAssets;
  greenAssets;
  dashboardLayer;
  pointAssets;
  lineAssets;
  currentAsset;
  currentFaults;
  assetSummary;

  mapSettings = {
    pinkVisibility: "none",
    yellowVisibility: "visible",
    orangeVisibility: "visible",
    redVisibility: "visible",
    greenVisibility: "visible",
    custLinesVisibility: "visible",
    custPointsVisibility: "none",
    hexGridVisibility: "none",
  };

  
  dashboardLayerSettings = {
    yellowVisibility: "visible",
    orangeVisibility: "visible",
    redVisibility: "visible",
    greenVisibility: "visible",
  }


  constructor(appStore){

    this.assetVisibilityHandlers = {
      pinkVisibility: { fetch: this.fetchPinkAssets, set: this.setPinkAssets },
      yellowVisibility: { fetch: this.fetchYellowAssets, set: this.setYellowAssets },
      orangeVisibility: { fetch: this.fetchOrangeAssets, set: this.setOrangeAssets },
      redVisibility: { fetch: this.fetchRedAssets, set: this.setRedAssets },
      greenVisibility: { fetch: this.fetchGreenAssets, set: this.setGreenAssets },
      custLinesVisibility: { fetch: this.fetchLineAssets, set: this.setLineAssets },
      custPointsVisibility: { fetch: this.fetchPointAssets, set: this.setPointAssets },
      hexGridVisibility: { fetch: this.fetchHexGrids, set: this.setHexGrids },
    };
    
    makeObservable(this, {
      assets: observable,
      pointsBaseLayer: observable,
      hexGrids: observable,
      linesBaseLayer: observable,
      mapSettings: observable,
      dashboardLayerSettings: observable,
      pinkAssets:observable,
      yellowAssets:observable,
      orangeAssets:observable,
      redAssets:observable,
      greenAssets: observable,
      pointAssets:observable,
      lineAssets:observable,
      currentAsset: observable,
      currentFaults: observable,
      assetSummary: observable,
      dashboardLayer: observable,

      setAssets: action,
      setPointsBaseLayer: action,
      setHexGrids: action,
      setLinesBaseLayer: action,
      setMapSettings: action,
      setDashboardLayerSettings: action,

      setPinkAssets: action,
      setYellowAssets: action,
      setOrangeAssets: action,
      setRedAssets: action,
      setGreenAssets: action,
      setLineAssets: action,
      setPointAssets: action,
      setAssetSummary: action,
      setDashboardLayer: action,

      fetchPinkAssets: action, 
      fetchYellowAssets: action, 
      fetchOrangeAssets: action, 
      fetchRedAssets: action, 
      fetchGreenAssets: action, 
      fetchLineAssets: action, 
      fetchPointAssets: action, 
      fetchHexGrids: action, 
      fetchDashboardLayer: action,

      setCurrentAsset: action,
      setCurrentFaults: action,

      clearAssets:action,

    })

    makePersistable(this, {
      name: 'AssetStore',
      properties: [
        'mapSettings',
        'currentAsset',
        'currentFaults',
        'dashboardLayerSettings'
      ],
      storage: new EncryptedLocalStorageAdapter(),
      expireIn: 86400000
    })

    this.appStore = appStore;
  }

  clearAssets = () => {
    this.setAssets(null)
    this.setPointsBaseLayer(null)
    this.setHexGrids(null)
    this.setLinesBaseLayer(null)
    this.setPinkAssets(null)
    this.setYellowAssets(null)
    this.setOrangeAssets(null)
    this.setRedAssets(null)
    this.setGreenAssets(null)
    this.setLineAssets(null)
    this.setPointAssets(null)
    this.setCurrentAsset(null)
    this.setCurrentFaults(null)
    this.setDashboardLayer(null)
  }

  fetchPinkAssets = () => {
    const params = {
      insp: this.appStore.inspectionStore.currentInspection.id,
    }
    return JwtClient.get(`assets/fetch_pink_assets?inspection_id=${params.insp}`).then((res) => {
      this.setPinkAssets(res)
    }).catch(e => {
      console.log(e)
    });
  } 

  fetchYellowAssets = () => {
    const params = {
      insp: this.appStore.inspectionStore.currentInspection.id,
    }
    return JwtClient.get(`assets/fetch_yellow_assets?inspection_id=${params.insp}`).then((res) => {
      this.setYellowAssets(res)
    }).catch(e => {
      console.log(e)
    });
  } 

  fetchOrangeAssets = () => {
    const params = {
      insp: this.appStore.inspectionStore.currentInspection.id,
    }
    return JwtClient.get(`assets/fetch_orange_assets?inspection_id=${params.insp}`).then((res) => {
      this.setOrangeAssets(res)
    }).catch(e => {
      console.log(e)
    });

  } 

  fetchRedAssets = () => {
    const params = {
      insp: this.appStore.inspectionStore.currentInspection.id,
    }
    return JwtClient.get(`assets/fetch_red_assets?inspection_id=${params.insp}`).then((res) => {
      this.setRedAssets(res)
    }).catch(e => {
      console.log(e)
    });

  } 

  fetchGreenAssets = () => {
    const params = {
      insp: this.appStore.inspectionStore.currentInspection.id,
    }
    return JwtClient.get(`assets/fetch_green_assets?inspection_id=${params.insp}`).then((res) => {
      this.setGreenAssets(res)
    }).catch(e => {
      console.log(e)
    });

  } 

  fetchLineAssets = () => {
    const params = {
      insp: this.appStore.inspectionStore.currentInspection.id,
    }
    return JwtClient.get(`base_layers/fetch_lines?inspection_id=${params.insp}`).then((res) => {
      this.setLinesBaseLayer(res)
    }).catch(e => {
      console.log(e)
    });

  } 

  fetchPointAssets = () => {
    const params = {
      insp: this.appStore.inspectionStore.currentInspection.id,
    }
    return JwtClient.get(`base_layers/fetch_points?inspection_id=${params.insp}`).then((res) => {
      this.setPointsBaseLayer(res)
    }).catch(e => {
      console.log(e)
    });

  } 

  fetchHexGrids = () => {
    const params = {
      insp: this.appStore.inspectionStore.currentInspection.id,
    }
    return JwtClient.get(`hex_grids/fetch_hex_grids?inspection_id=${params.insp}`).then((res) => {
      this.setHexGrids(res)
    }).catch(e => {
      console.log(e)
    });

  } 

  fetchDashboardLayer = () => {
    const params = {
      insp: this.appStore.inspectionStore.currentInspection?.id,
      code: this.appStore.dashboardStore.activeData
    }
    if(!params.insp || !params.code) return
    return JwtClient.get(`assets/fetch_dashboard_filter?inspection_id=${params.insp}&code=${params.code}`).then((res) => {
      this.setDashboardLayer(res)
      this.appStore.dashboardStore.setActiveDataSummary(res)
    }).catch(e => {
      console.log(e)
    });
  } 

  setPinkAssets = (asset) => {
    this.pinkAssets = asset
  }

  setYellowAssets = (asset) => {
    this.yellowAssets = asset
  }

  setOrangeAssets = (asset) => {
    this.orangeAssets = asset
  }

  setRedAssets = (asset) => {
    this.redAssets = asset
  }

  setGreenAssets = (asset) => {
    this.greenAssets = asset
  }

  setLineAssets = (asset) => {
    this.lineAssets = asset
  }

  setPointAssets = (asset) => {
    this.pointAssets = asset
  }

  setDashboardLayer = (asset) => {
    this.dashboardLayer = asset
  }

  setMapSettings = (key, value) => {
    const handler = this.assetVisibilityHandlers[key];
    if (handler) {
      if (value === "visible") {
        handler.fetch.call(this);
      } else {
        handler.set.call(this, null);
      }
    }

    // Update map settings
    this.mapSettings = {
      ...this.mapSettings,
      [key]: value,
    };
  };

  setDashboardLayerSettings = (key, value) => {
    // Update map settings
    this.dashboardLayerSettings = {
      ...this.dashboardLayerSettings,
      [key]: value,
    };
  };
  
  setAssets = (assets) => {
    this.assets = assets
  }
  
  setPointsBaseLayer = (points) => {
    this.pointsBaseLayer = points
  }
  
  setHexGrids = (hexGrids) => {
    this.hexGrids = hexGrids
  }
  
  setLinesBaseLayer = (lines) => {
    this.linesBaseLayer = lines
  }
  
  setCurrentFaults = (faults) => {
    this.currentFaults = faults
  }

  setAssetSummary = (coll) => {
    this.assetSummary = coll
  }

  setCurrentAsset = (currentAsset, key=null, value=null, fetch=true) => {
    this.currentAsset = null;
    if(!key || !value) {
      this.currentAsset = currentAsset;
    } else if (key && value && !fetch){
      this.currentAsset = {
        ...currentAsset,
        [key]: value
      };
    }
    if(currentAsset !== null && fetch) {
      this.setCurrentFaults(null)
      const fetchImageData = async() => {
        try {
          await this.fetchAssetImages();
        } catch (error) {
          console.error(error)
        }
      }

      const fetchFaultData = async() => {
        try {
          await this.fetchAssetFaults();
        } catch (error) {
          console.error(error)
        }
      }

      fetchImageData();
      fetchFaultData();
    }
  }
  fetchAssets = () => {
    const params = {
      insp: this.appStore.inspectionStore.currentInspection.id,
    }
    return JwtClient.get(`assets/fetch_map_data?inspection_id=${params.insp}`).then((res) => {
      this.setAssets(res)
    }).catch(e => {
      console.log(e)
    });
  }

  fetchBaseLayer = () => {
    const params = {
      insp: this.appStore.inspectionStore.currentInspection.id,
    }
    return JwtClient.get(`base_layers/fetch_map_data?inspection_id=${params.insp}`).then((res) => {
      this.setPointsBaseLayer(res.points)
      this.setLinesBaseLayer(res.lines)
    }).catch(e => {
      console.log(e)
    });
  }

  fetchAssetImages = () => {
    return JwtClient.get(`assets/${this.currentAsset.properties.id}/fetch_images`).then((res) => {
      this.setCurrentAsset(this.currentAsset, "imageUrls", JSON.parse(res), false)
    });
  }

  fetchAssetFaults = () => {
    return JwtClient.get(`asset_faults/fetch_data?asset_id=${this.currentAsset.properties.id}`).then((res) => {
      let faultImageUrls = [];
      let obj = {}
      res.forEach(fault => {
        faultImageUrls.push(fault.faultImage)
      });
      obj['allFaultImageUrls'] = faultImageUrls
      obj['faults'] = res
      this.setCurrentFaults(obj)
    });
  }

  fetchAssetSummary = (id) => {
    return JwtClient.get(`assets/${id}/summary`).then((res) => {
      this.setAssetSummary(res)
    }).catch(e => {
      if(e.status.code === 404) {
        this.setAssetSummary(404)
      }
    });
  }

}