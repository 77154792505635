import { observable, action, makeObservable, runInAction, toJS } from "mobx";
import { BaseModel } from 'kilowatt-front/state/BaseModel';
import GroupStore from "../stores/GroupStore";
import DomainStore from "../stores/DomainStore";
import ProjectStore from "../stores/ProjectStore";
import API from "kilowatt-front/data/api";

export class CustomerModel extends BaseModel {
  admined;
  authorizationMode;
  groups = [];
  domains = [];

  constructor(data) {
    if (data.groups) {
      data.groups = GroupStore.addOrUpdateMulti(data.groups);
    }

    if (data.domains) {
      data.domains = DomainStore.addOrUpdateMulti(data.domains);
    }
    if (data.projects) {
      data.projects = ProjectStore.addOrUpdateMulti(data.projects);
    }

    super(data);

    this.admined = data.admined;
    this.authorizationMode = data.authorizationMode;
    this.groups = data.groups;
    this.domains = data.domains;
    this.projects = data.projects;
    
    makeObservable(this, {
      admined: observable,
      authorizationMode: observable,
      groups: observable,
      domains: observable,
      projects: observable,
      addDomain: action,
      removeDomain: action,
      _removedDomainLoaded: action,
      newGroup:action,
      removeGroup: action,
      _addingDomainFailed: action,
      _removedGroupLoaded: action,
      setAuthorizationMode: action,
    });
  }

  refresh = () => {
    return API.fetchAdminedCustomer(this.id).then((data) =>{
      runInAction(() => {
        const refreshedModel = new CustomerModel(data)
        this.update(refreshedModel)
      })
    })
  }

  // DOMAIN ACTIONS---------------------------------------------------
  addDomain = (domain) => {
    return API.addDomainToCustomer(this.id, domain)
    .then((newDomain) => {
      runInAction(() => {
        const updatedDomain = DomainStore.addOrUpdate(newDomain);
        if (!this.domains.some(g => g.id === updatedDomain.id)) {
          this.domains.push(updatedDomain);
        }
      });
    })
    .catch((response) => {
      this._addingDomainFailed(domain, response.entity.error);
    });
  }

  _addingDomainFailed = (domain, error) => {
    const msg =
      error ||
      `Something went wrong while adding domain "${domain}" to customer "${this.name}"`;
    alert(msg);
  }

  removeDomain = (domainToRemove) => {
    return API.removeDomainFromCustomer(this.id, domainToRemove.id)
    .then(() => {
      this._removedDomainLoaded(domainToRemove)
    })
    .then(() => this.refresh());
  }

  _removedDomainLoaded = (oldDomain) => {
    this.domains = this.domains.filter(domain => domain !== oldDomain);
  }

  // GROUP ACTIONS---------------------------------------------------
  newGroup = (groupData) => {
    return API.createGroup(this.id, groupData)
      .then((group) => {
        runInAction(() => {
          const updatedGroup = GroupStore.addOrUpdate(group);
          if (!this.groups.some(g => g.id === updatedGroup.id)) {
            this.groups.push(updatedGroup);
          }
        });
      });
  }

  removeGroup = (groupToRemove) => {
    return API.removeGroup(groupToRemove)
    .then(() => {
      this._removedGroupLoaded(groupToRemove)
    })
    .then(() => {
      this.refresh()
    });
  }

  _removedGroupLoaded = (oldGroup) => {
    const groupIndex = this.groups.findIndex(group => group.id === oldGroup.id);
    if (groupIndex > -1) {
      this.groups.splice(groupIndex, 1);
    }
  };

  // SETTINGS ACTIONS---------------------------------------------------
  setAuthorizationMode = (authorizationMode) => {
    this.authorizationMode = authorizationMode;
    return API.updateCustomer(this.id, { authorizationMode });
  }
}