import CryptoJS from 'crypto-js';

class EncryptedLocalStorageAdapter {
  secretKey = import.meta.env.VITE_LOCAL_STORAGE_SECRET_KEY
  
  setItem = (key, content) => {
    if (JSON.parse(content)) {
      content = JSON.parse(content)
    }
    return new Promise((resolve, reject) => {
      try {
        if (typeof content === 'object' && content !== null) {
          const encryptedContent = Object.entries(content).reduce((acc, [key, value]) => {
            acc[key] = this.encrypt(JSON.stringify(value));
            return acc;
          }, {});
          window.localStorage.setItem(key, JSON.stringify(encryptedContent));
        } else {
          window.localStorage.setItem(key, this.encrypt(JSON.stringify(content)));
        }
        resolve();
      } catch (error) {
        reject(error)
      }
    });
  }

  removeItem = (key) => {
    return new Promise((resolve, reject) => {
      try {
        window.localStorage.removeItem(key)
        resolve()
      } catch (error) {
        console.error("Error removing item from localStorage:", error);
        reject(error);
      }
    })
  }

  getItem = (key) => {
    return new Promise((resolve, reject) => {
      let item = window.localStorage.getItem(key);

      if (item) {
        try {
          let parsedItem = JSON.parse(item);
          const decryptedItem = Object.entries(parsedItem).reduce((acc, [key, value]) => {
            acc[key] = JSON.parse(this.decrypt(value));
            return acc;
          }, {});
          resolve(decryptedItem);
        } catch (error) {
          console.error("Error decrypting or parsing item from localStorage:", error);
          resolve(null);
        }
      } else {
        resolve(null);
      }
    });
  }

  encrypt = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), this.secretKey).toString();
  };
  
  decrypt = (cipherText) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, this.secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };
}

export default EncryptedLocalStorageAdapter;