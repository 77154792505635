import React, { lazy, Suspense, useEffect  } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";

import localStorageWrapper from "kilowatt-front/util/localStorageWrapper";
// stores
import AuthService from "kilowatt-front/services/AuthService";

// components
import Loader from "kilowatt-front/shared_components/Loader/Loader"
import NotFound from "kilowatt-front/shared_components/Error/NotFound.jsx";

// child routes
const UtilitiesRoutes = lazy(() => import("./Utilities/UtilitiesRoutes"));

// ----END OF IMPORTS-------------------------------------------------------

const ProtectedRoutes = ({ auth }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.isAuthenticated()) {
      const loginPath = '/app/auth';
      if(window.location.pathname !== loginPath) {
        localStorageWrapper.setItem('original_location', window.location.pathname + window.location.search);
      }
      navigate('/app/auth', { replace: true });
    } else {
      // Proceed with setting the token if authenticated
      auth.setTokenAndFinish(navigate);
    }
  }, [auth, navigate]);

  return (
    <Routes>
      <Route
        path="utilities/*"
        element={
          <Suspense fallback={<div className="loader full-height"><Loader /></div>}>
            <UtilitiesRoutes/>
          </Suspense>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

ProtectedRoutes.propTypes = {
  auth: PropTypes.instanceOf(AuthService),
};

export default inject("auth")(observer(ProtectedRoutes));