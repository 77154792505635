import React from "react";

// styling
import "./NotFound.scss";

// ----END OF IMPORTS-------------------------------------------------------

const NotFound = () => {
  return (
    <div className="not-found full-height">
      <div className="center">
        <div className="message">
          <div className="not-found-code">404</div>
          <p>We are sorry but the page you are looking for does not exist or you do not have authorization to access it.</p>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
