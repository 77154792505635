import { makeObservable, observable, action, computed } from "mobx";

// services
import JwtClient from "kilowatt-front/data/api/jwt_client";
import * as elevio from "kilowatt-front/util/elevio";

// ----END OF IMPORTS-------------------------------------------------------


function setElevioFromProfile(profile) {
  if (profile) {
    elevio.changeUser({
      email: profile.email,
      user_hash: profile.elevio_email_key
    });
  } else {
    elevio.logoutUser();
  }
}
export class CurrentUserStore {
  userProfile = null;
  hasAdminedGroupsValue = false;
  isInternalUserValue = false;

  constructor() {
    makeObservable(this, {
      userProfile: observable,
      hasAdminedGroupsValue: observable,
      isInternalUserValue: observable,
      setProfile: action,
      updateHasAdminedGroups: action,
      updateEmailVerification: action,
      updateIsInternalUser: action,
      hasAdminedGroups: computed,
      isInternalUser: computed
    })
  }

  setProfile = (newProfile) => {
    this.userProfile = newProfile;

    setElevioFromProfile(this.userProfile);
  }

  updateHasAdminedGroups(val) {
    this.hasAdminedGroupsValue = val;
  }

  updateIsInternalUser(val) {
    this.isInternalUserValue = val;
  }

  updateEmailVerification = (verification) => {
    if (this.userProfile) this.userProfile.email_verified = verification;
  }
  
  get hasAdminedGroups() { 
    return this.hasAdminedGroupsValue;
  }
  
  get isInternalUser() { 
    return this.isInternalUserValue;
  }

  updateProfile = () => {
    return JwtClient.get("/users/me")
      .then((data) => {
        this.updateHasAdminedGroups(data["hasAdminedGroups?"]);
        this.updateEmailVerification(data["emailVerification"]);
        this.updateIsInternalUser(data["internalUser"])
      })
      .catch((ex) => {
        console.error(ex);
      });
  }

  resendVerificationEmail() {
    return JwtClient.post("/users/resend_email").catch((ex) => {
      console.error(ex);
    });
  }

}

export default new CurrentUserStore();