import { camelizeKeys, decamelizeKeys } from "humps";
import interceptor from "rest/interceptor";

export const camelize = interceptor({
  success: (response) => ({
    ...response,
    entity: camelizeKeys(response.entity),
  }),
});

export const decamelize = interceptor({
  request: (request) => ({
    ...request,
    entity: decamelizeKeys(request.entity),
  }),
});