import { action, extendObservable, makeObservable, observable } from "mobx";
import { BaseModel } from "../../BaseModel"
import UserStore from "../stores/UserStore";
import ProjectStore from "../stores/ProjectStore";

import API from "kilowatt-front/data/api";

export class GroupModel extends BaseModel {
  users = [];
  name;

  constructor(data) {
    data._permissions = {};
    if (data.users) {
      data.users = data.users.map((userData) => {
        const { permission, ...otherData } = userData;
        data._permissions[userData.id] = permission;
        return UserStore.addOrUpdate(otherData);
      });
    } else {
      data.users = [];
    }
    if (data.projects) {
      data.projects = ProjectStore.addOrUpdateMulti(data.projects);
    }
    super(data);
    this.users = data.users;
    this.name = data.name;
    this.projects = data.projects;

    makeObservable(this, {
      users: observable,
      name: observable,
      projects: observable,
      rename: action,
      addUser: action,
      _userAdded: action,
      changeUserPermission: action,
      removeUser: action,
    })
  }

  permissionOfUser = (user) => {
    return this._permissions[user.id];
  }

  rename = (name) => {
    this.name = name;
    return API.updateGroup({ id: this.id, name });
  }

  addProject(project) {
    this.projects = this.projects ? [...this.projects, project] : [project];
    return API.addProjectToGroup(this.id, project.id);
  }

  removeProject(project) {
    this.projects.remove(project);
    return API.removeProjectFromGroup(this.id, project.id);
  }

  addUser = (email, permission) => {
    return API.addUserToGroup(this.id, email, permission).then((user) => {
      this._userAdded(user, permission);
    });
  }

  _userAdded = (user, permission) => {
    this.users = this.users ? [...this.users, user] : [user];
    this._setPermission(user.id, permission);
  }

  changeUserPermission = (user, permission) => {
    this._setPermission(user.id, permission);
    return API.changeUserPermission(this.id, user.id, permission);
  }

  _setPermission = (userId, permission) => {
    extendObservable(this._permissions, { [userId]: permission });
  }

  removeUser = (user) => {
    this.users.remove(user);
    return API.removeUserFromGroup(this.id, user.id);
  }
}