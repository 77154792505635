import { makeObservable, observable, action, computed } from "mobx";
import JwtClient from "../../../data/api/jwt_client"
import { makePersistable } from 'mobx-persist-store';
import EncryptedLocalStorageAdapter from "../../../util/encryptedLocalStorageAdapter";

export class DashboardStore {

  maintenanceSummary = null;
  activeData = null;
  activeDataSummary = null;

  constructor(appStore) {

    makeObservable(this, {
      maintenanceSummary: observable,
      activeData: observable,
      activeDataSummary: observable,
      setMaintenanceSummary: action,
      setActiveData: action,
      setActiveDataSummary: action,
    });

    makePersistable(this, {
      name: 'DashboardStore',
      properties: ['activeData'],
      storage: new EncryptedLocalStorageAdapter(),
      expireIn: 86400000,
    });

    this.appStore = appStore;
  }

  setMaintenanceSummary = (maintenanceSummary) => {
    this.maintenanceSummary = maintenanceSummary;
  }

  setActiveData = (activeData) => {
    this.activeData = activeData;
    this.appStore.assetStore.fetchDashboardLayer();
    if (activeData) {
      this.appStore.assetStore.setDashboardLayerSettings('yellowVisibility', 'visible')
      this.appStore.assetStore.setDashboardLayerSettings('orangeVisibility', 'visible')
      this.appStore.assetStore.setDashboardLayerSettings('redVisibility', 'visible')
      this.appStore.assetStore.setDashboardLayerSettings('greenVisibility', 'visible')
      this.appStore.assetStore.setMapSettings('pinkVisibility', 'none');
      this.appStore.assetStore.setMapSettings('yellowVisibility', 'none');
      this.appStore.assetStore.setMapSettings('orangeVisibility', 'none');
      this.appStore.assetStore.setMapSettings('redVisibility', 'none');
      this.appStore.assetStore.setMapSettings('greenVisibility', 'none');
      this.appStore.assetStore.setCurrentAsset(null);
    } else {
      this.appStore.assetStore.setDashboardLayerSettings('yellowVisibility', 'none')
      this.appStore.assetStore.setDashboardLayerSettings('orangeVisibility', 'none')
      this.appStore.assetStore.setDashboardLayerSettings('redVisibility', 'none')
      this.appStore.assetStore.setDashboardLayerSettings('greenVisibility', 'none')
      this.appStore.assetStore.setCurrentAsset(null);
    }
  }

  setActiveDataSummary = async (activeDataCollection) => {
    if (!activeDataCollection || activeDataCollection.features.length === 0) {
      this.activeDataSummary = null;
    } else {
      let summary = {
        'Vegetation': 0,
        'High': 0,
        'Medium': 0,
        'Low': 0,
      }
      await activeDataCollection.features.map((feature) => {
        if (feature.properties.intensity === 1) {
          summary['Low'] += 1;
        } else if (feature.properties.intensity === 2) {
          summary['Vegetation'] += 1;
        } else if (feature.properties.intensity === 3) {
          summary['Medium'] += 1;
        } else if (feature.properties.intensity === 4) {
          summary['High'] += 1;
        }
      })
      this.activeDataSummary = summary;
    }
  }

  fetchFaultMaintenanceSummary = () => {
    const params = {
      inspection_id: this.appStore.inspectionStore.currentInspection.id
    }
    return JwtClient.get(`dashboards/fault_maintenance_summary?inspection_id=${params.inspection_id}`).then((res) => {
      this.setMaintenanceSummary(res);
    })
  }
}