import React from "react";
import PropTypes from "prop-types";

// styling
import "./Loader.scss";

// ----END OF IMPORTS-------------------------------------------------------

const Loader = ({ size }) => {
  const getFontSize = () => {
    switch (size) {
      case "small":
        return 3;
      case "medium":
        return 5;
      case "large":
        return 7;
      default:
        return 3;
    }
  };

  const fontSize = getFontSize();

  const style = {
    fontSize: `${fontSize}px`,
  };

  return <div className="spinner-loader" data-testid='loader' style={style} />;
};

Loader.propTypes = {
  size: PropTypes.string,
};

export default Loader;