import { observable, action, makeObservable, toJS } from "mobx";
import JwtClient from "../../../data/api/jwt_client"
import { makePersistable } from 'mobx-persist-store';
import EncryptedLocalStorageAdapter from "../../../util/encryptedLocalStorageAdapter";


export class InspectionStore{
  inspections = [];
  currentInspection;
  fetchingData = false;
  inspectionFiles;
  fetchingFiles = false;
  fetchingFilesError = false;
  downloadableProjectFiles;
  downloadableGeneratedFiles;
  downloadsToRemove = [];
  storeAssetStatus = null;
  storeBaseLayerStatus = null;
  storeHexGridStatus = null;
  genDiscShpStatus = null;
  genInspShpStatus = null;
  genPictShpStatus = null;
  genProjShpStatus = null;
  dataSelectOpen = true;
  markerDataOpen = true;
  layerSelectOpen = true;
  dashboardLayerOpen = true;
  baseMapSelectOpen = true;
  summaryOpen = true;
  imagesOpen = true;
  leftPanelOpen = true;
  metrics = null;
  baseMapStyle = "satellite";
  baseMapStyleOptions = {
    'satellite': 'mapbox://styles/kesprydrones/cj1fqeoop00022rp0p3ghvo6n',
    'dark': 'mapbox://styles/kesprydrones/cj5mn4kvv3lgk2rn1wq4m9xcl',
    'light': 'mapbox://styles/kesprydrones/clwb0bx5f01g801qg8935cpxo',
  }
  autoZoom = true;

  constructor(appStore){

    makeObservable(this, {
      inspections: observable,
      currentInspection:observable,
      metrics: observable,
      baseMapStyle: observable,
      autoZoom: observable,

      fetchingData: observable,
      inspectionFiles: observable,
      fetchingFiles:observable,
      fetchingFilesError: observable,
      downloadableProjectFiles: observable,
      downloadableGeneratedFiles: observable,
      downloadsToRemove: observable,
      storeAssetStatus: observable,
      storeBaseLayerStatus: observable,
      storeHexGridStatus: observable,
      dataSelectOpen: observable,
      markerDataOpen: observable,
      layerSelectOpen: observable,
      dashboardLayerOpen: observable,
      baseMapSelectOpen: observable,
      imagesOpen: observable,
      leftPanelOpen: observable,
      summaryOpen: observable,
      genDiscShpStatus: observable,
      genInspShpStatus: observable,
      genPictShpStatus: observable,
      genProjShpStatus: observable,

      setMetrics: action,
      setBaseMapStyle: action,
      setInspections: action,
      setCurrentInspection: action,
      setFetchingData: action,
      setInspectionFiles: action,
      setFetchingFiles: action,
      setFetchingFilesError: action,
      setDownloadableProjectFiles: action,
      setDownloadableGeneratedFiles: action,
      setDownloadsToRemove: action,
      setStoreBaseLayerStatus: action,
      setStoreHexGridStatus: action,
      setStoreAssetStatus: action,
      setGenDiscShpStatus: action,
      setGenInspShpStatus: action,
      setGenPictShpStatus: action,
      setGenProjShpStatus: action,
      startProcessMapData: action,
      startStoreAssetData: action,
      startStoreBaseLayerData: action,
      startStoreHexGridData: action,
      startGenDiscShp: action,
      startGenInspShp: action,
      startGenPictShp: action,
      startGenProjShp: action,
      removeDownloads: action,
      fetchStoreBaseLayerStatus: action,
      fetchStoreHexGridStatus: action,
      fetchStoreAssetStatus: action,
      fetchGenDiscShpStatus: action,
      fetchGenInspShpStatus: action,
      fetchGenPictShpStatus: action,
      fetchGenProjShpStatus: action,
      setDataSelectOpen: action,
      setMarkerDataOpen: action,
      setLayerSelectOpen: action,
      setDashboardLayerOpen: action,
      setBaseMapSelectOpen: action,
      setImagesOpen: action,
      setLeftPanelOpen: action,
      setSummaryOpen: action,
      setAutoZoom: action,
      updateCurrentInspection: action
    })

    makePersistable(this, {
      name: 'InspectionStore',
      properties: [
        'currentInspection',
        'dataSelectOpen',
        'markerDataOpen',
        'layerSelectOpen',
        'dashboardLayerOpen',
        'imagesOpen',
        'leftPanelOpen',
        'baseMapStyle',
        'baseMapSelectOpen',
        'autoZoom'
      ],
      storage: new EncryptedLocalStorageAdapter(),
      expireIn: 86400000
    })

    this.appStore = appStore;
  }

  setInspections = (inspections) => {
    this.inspections = inspections;
  }

  setBaseMapStyle = (option) => {
    this.baseMapStyle = option;
  }

  setCurrentInspection = (currentInspection) => {
    console.log(currentInspection)
    this.currentInspection = currentInspection;
    this.appStore.assetStore.clearAssets();
    this.appStore.dashboardStore.setMaintenanceSummary(null);
    this.appStore.dashboardStore.setActiveData(null);
    this.setMetrics(null);

    this.setInspectionFiles(null);
    this.setDownloadableProjectFiles(null);
    this.setDownloadableGeneratedFiles(null);
    if(currentInspection !== null) {
      const fetchData = async() => {
        this.setFetchingData(true)
        try {
          await this.fetchMetrics();
          await this.appStore.assetStore.fetchAssets();
          await this.appStore.assetStore.fetchPinkAssets();
          await this.appStore.assetStore.fetchYellowAssets();
          await this.appStore.assetStore.fetchOrangeAssets();
          await this.appStore.assetStore.fetchRedAssets();
          await this.appStore.assetStore.fetchGreenAssets();
          await this.appStore.assetStore.fetchLineAssets();
          await this.appStore.assetStore.fetchPointAssets();
          await this.appStore.dashboardStore.fetchFaultMaintenanceSummary();
        } catch (error) {
          console.error(error)
        } finally {
          this.setFetchingData(false)
        }
      }
      fetchData();
    }
  }

  setAutoZoom = (autoZoom) => {
    this.autoZoom = autoZoom;
  }

  setFetchingData = (fetchingData) => {
    this.fetchingData = fetchingData;
  }

  setInspectionFiles = (inspectionFiles) => {
    this.inspectionFiles = inspectionFiles;
    this.setFetchingFiles(false);
  }

  setFetchingFiles = (fetchingFiles) => {
    this.fetchingFiles = fetchingFiles;
  }

  setFetchingFilesError = (fetchingFilesError) => {
    this.fetchingFilesError = fetchingFilesError;
  }

  setDownloadableProjectFiles = (downloadableProjectFiles) => {
    this.downloadableProjectFiles = downloadableProjectFiles;
  }

  setDownloadableGeneratedFiles = (downloadableGeneratedFiles) => {
    this.downloadableGeneratedFiles = downloadableGeneratedFiles;
  }

  setDownloadsToRemove = (downloadsToRemove, removeFromList= false) => {
    if (removeFromList) {
      this.downloadsToRemove.remove(downloadsToRemove)
    } else {
      this.downloadsToRemove.push(downloadsToRemove)
    }
    this.setDownloadableProjectFiles(this.downloadableProjectFiles?.filter(item => item !== downloadsToRemove))
  }

  setStoreBaseLayerStatus = (storeBaseLayerStatus) => {
    this.storeBaseLayerStatus = storeBaseLayerStatus;
  }

  setStoreHexGridStatus = (storeHexGridStatus) => {
    this.storeHexGridStatus = storeHexGridStatus;
  }

  setStoreAssetStatus = (storeAssetStatus) => {
    this.storeAssetStatus = storeAssetStatus;
  }

  setGenDiscShpStatus = (genDiscShpStatus) => {
    this.genDiscShpStatus = genDiscShpStatus;
  }

  setGenInspShpStatus = (genInspShpStatus) => {
    this.genInspShpStatus = genInspShpStatus;
  }

  setGenPictShpStatus = (genPictShpStatus) => {
    this.genPictShpStatus = genPictShpStatus;
  }

  setGenProjShpStatus = (genProjShpStatus) => {
    this.genProjShpStatus = genProjShpStatus;
  }

  setDataSelectOpen = (dataSelectOpen) => {
    this.dataSelectOpen = dataSelectOpen;
  }

  setMarkerDataOpen = (markerDataOpen) => {
    this.markerDataOpen = markerDataOpen;
  }

  setLayerSelectOpen = (layerSelectOpen) => {
    this.layerSelectOpen = layerSelectOpen;
  }

  setDashboardLayerOpen = (dashboardLayerOpen) => {
    this.dashboardLayerOpen = dashboardLayerOpen;
  }

  setBaseMapSelectOpen = (baseMapSelectOpen) => {
    this.baseMapSelectOpen = baseMapSelectOpen;
  }

  setImagesOpen = (imagesOpen) => {
    this.imagesOpen = imagesOpen;
  }

  setLeftPanelOpen = (leftPanelOpen) => {
    this.leftPanelOpen = leftPanelOpen;
  }

  setSummaryOpen = (summaryOpen) => {
    this.summaryOpen = summaryOpen;
  }

  setMetrics = (metrics) => {
    this.metrics = metrics;
  }

  addUtilityInspection = (name, inspection_type, uri) => {
    const params = {
      name: name,
      inspection_type: inspection_type,
      customer_id: this.appStore.customerStore.currentCustomer.id,
      project_id: this.appStore.projectStore.currentProject.id,
      s3_location: uri
    }
    return JwtClient.post("inspections", params).then((res) => {
      return "done";
    });

  }

  addUtilityInspectionDownloads = (files) => {
    const params = {
      files: files,
      customer_id: this.appStore.customerStore.currentCustomer.id,
      project_id: this.appStore.projectStore.currentProject.id,
      inspection_id: this.currentInspection.id
    }
    return JwtClient.post("downloads", params).then((res) => {
      return "done";
    }).then(() => {
      this.fetchDownloadableProjectFiles()
    })
  }

  removeDownloads = () => {
    const params = {
      customer_id: this.appStore.customerStore.currentCustomer.id,
      project_id: this.appStore.projectStore.currentProject.id,
      inspection_id: this.currentInspection.id
    }
    this.downloadsToRemove?.map(file => {
      return JwtClient.delete(`downloads/${file.id}`, params).then((res) => {
        this.setDownloadsToRemove(file, true)
      });
    })
  }

  updateDownloadableProjectFiles = (filesToUpdate) => {
    filesToUpdate.map(file => {
      const params = {
        file: file
      }
      return JwtClient.put(`downloads/${file.id}`, params).then((res) => {
        return "done";
      });
    })
    this.fetchDownloadableProjectFiles()
    
  }

  updateCurrentInspection = (key, value) => {
    this.currentInspection = {
      ...this.currentInspection,
      [key]: value
    }
    this.setCurrentInspection(this.currentInspection)
  }

  fetchInspections = () => {
    return JwtClient.get(`projects/${this.appStore.projectStore.currentProject.id}/inspections`).then((res) => {
      if (res.length > 0) {
        this.setInspections(res);
        return this.inspections;
      } else {
        this.setInspections(null);
        return this.inspections;
      }
    });
  }

  fetchInspectionFiles = () => {
    if(this.fetchingFiles === false) {
      this.setFetchingFilesError(false)
      this.setFetchingFiles(true)
      return JwtClient.get(`inspections/${this.currentInspection.id}/files`).then((res) => {
        if (res.inspectionFileTreeData) {
          this.setInspectionFiles(res.inspectionFileTreeData)
        } else {
          this.setFetchingFilesError(true)
        }
      }).catch(e => {
        this.setFetchingFilesError(true)
      });
    }
  }

  fetchDownloadableProjectFiles = () => {
    this.setDownloadableProjectFiles(null);
    const params = {
      customer_id: this.appStore.customerStore.currentCustomer.id,
      project_id: this.appStore.projectStore.currentProject.id,
      inspection_id: this.currentInspection.id
    }
    return JwtClient.get(`downloads?customer_id=${params.customer_id}&project_id=${params.project_id}&inspection_id=${params.inspection_id}`).then((res) => {
      if (res.downloads) {
        this.setDownloadableProjectFiles(res.downloads);
      }
    })
  }

  fetchDownloadableGeneratedFiles = () => {
    this.setDownloadableGeneratedFiles(null);
    const params = {
      customer_id: this.appStore.customerStore.currentCustomer.id,
      project_id: this.appStore.projectStore.currentProject.id,
      inspection_id: this.currentInspection.id
    }
    return JwtClient.get(`fetch_generated_downloads?customer_id=${params.customer_id}&project_id=${params.project_id}&inspection_id=${params.inspection_id}`).then((res) => {
      if (res.downloads) {
        this.setDownloadableGeneratedFiles(res.downloads);
      }
    })
  }

  startProcessMapData = (kml_file, xml_file) => {
    const params = {
      kml_file: kml_file,
      xml_file: xml_file
    }
    return JwtClient.post(`inspections/${this.currentInspection.id}/process_map_data`, params).then((res) => {
      if (res.downloads) {
        this.setDownloadableProjectFiles(res.downloads);
      }
    })
  }

  startStoreAssetData = (xml_file) => {
    const params = {
      xml_file: xml_file
    }
    return JwtClient.post(`inspections/${this.currentInspection.id}/store_asset_data`, params).then((res) => {
    })
  }

  startStoreBaseLayerData = (kml_file) => {
    const params = {
      kml_file: kml_file
    }
    return JwtClient.post(`inspections/${this.currentInspection.id}/store_base_layer_data`, params).then((res) => {
    })
  }

  startStoreHexGridData = (kml_file) => {
    const params = {
      kml_file: kml_file
    }
    return JwtClient.post(`inspections/${this.currentInspection.id}/store_hex_grid_data`, params).then((res) => {
    })
  }

  startGenDiscShp = () => {
    return JwtClient.post(`inspections/${this.currentInspection.id}/generate_disc_shp`).then((res) => {
      if (res.downloads) {
        this.setDownloadableProjectFiles(res.downloads);
      }
    })
  }

  startGenInspShp = () => {
    return JwtClient.post(`inspections/${this.currentInspection.id}/generate_insp_shp`).then((res) => {
    })
  }

  startGenPictShp = () => {
    return JwtClient.post(`inspections/${this.currentInspection.id}/generate_pict_shp`).then((res) => {
    })
  }

  startGenProjShp = () => {
    return JwtClient.post(`inspections/${this.currentInspection.id}/generate_proj_shp`).then((res) => {
    })
  }

  fetchStoreBaseLayerStatus = () => {
    const params = {
      inspection_id: this.currentInspection.id
    }
    return JwtClient.get(`inspections/${params.inspection_id}/status_store_base_layer_data`).then((res) => {
      this.setStoreBaseLayerStatus(res.status);
    })
  }

  fetchStoreHexGridStatus = () => {
    const params = {
      inspection_id: this.currentInspection.id
    }
    return JwtClient.get(`inspections/${params.inspection_id}/status_store_hex_grid_data`).then((res) => {
      this.setStoreHexGridStatus(res.status);
    })
  }

  fetchStoreAssetStatus = () => {
    const params = {
      inspection_id: this.currentInspection.id
    }
    return JwtClient.get(`inspections/${params.inspection_id}/status_store_asset_data`).then((res) => {
      this.setStoreAssetStatus(res.status);
    })
  }

  fetchGenDiscShpStatus = () => {
    const params = {
      inspection_id: this.currentInspection.id
    }
    return JwtClient.get(`inspections/${params.inspection_id}/status_generate_disc_shapefile`).then((res) => {
      this.setGenDiscShpStatus(res.status);
    })
  }

  fetchGenInspShpStatus = () => {
    const params = {
      inspection_id: this.currentInspection.id
    }
    return JwtClient.get(`inspections/${params.inspection_id}/status_generate_insp_shapefile`).then((res) => {
      this.setGenInspShpStatus(res.status);
    })
  }

  fetchGenPictShpStatus = () => {
    const params = {
      inspection_id: this.currentInspection.id
    }
    return JwtClient.get(`inspections/${params.inspection_id}/status_generate_pict_shapefile`).then((res) => {
      this.setGenPictShpStatus(res.status);
    })
  }

  fetchGenProjShpStatus = () => {
    const params = {
      inspection_id: this.currentInspection.id
    }
    return JwtClient.get(`inspections/${params.inspection_id}/status_generate_proj_shapefile`).then((res) => {
      this.setGenProjShpStatus(res.status);
    })
  }

  fetchMetrics = () => {
    const params = {
      inspection_id: this.currentInspection.id
    }
    return JwtClient.get(`inspections/${params.inspection_id}/fetch_metrics`).then((res) => {
      this.setMetrics(res);
    })
  }
  
  storeNewDataLocation = (newDataLocation) => {
    const params = {
      inspection_id: this.currentInspection.id,
      newDataLocation: newDataLocation
    }
    return JwtClient.post(`inspections/${params.inspection_id}/store_new_data_location`, params).then((res) => {
      this.updateCurrentInspection('s3Location', newDataLocation);
    })
  }
}
